:root{
    --f2m-orange: #F2AA00;
    --f2m-orange-light: #fff3d6;
    --f2m-blue: #3D5465;
    --f2m-blue-light: #dfeff7;
    --f2m-white: #F9F8F8;
    --f2m-black: rgba(0, 0, 0, 0.85);
}


.cs-conversation-header{
    background-color: var(--f2m-blue);
}
.cs-conversation-header__content {
    background-color: var(--f2m-blue);
    color: var(--f2m-white);
}
.cs-conversation-header__content > div{
    background-color: var(--f2m-blue)!important;
    color: var(--f2m-white)!important;
}
.message-incoming > .cs-message__content-wrapper > .cs-message__content{
    background-color: var(--f2m-blue)!important;
    color: var(--f2m-white)!important;
}
.message-outgoing > .cs-message__content-wrapper > .cs-message__content{
    background-color: var(--f2m-blue-light)!important;
    color: var(--f2m-black)!important;
}
.cs-message-input__content-editor-wrapper, .cs-message-input__content-editor-wrapper > *, .cs-message-input__content-editor-wrapper > * > * {
    background-color: var(--f2m-blue-light)!important;
    color: var(--f2m-black)!important;
}
.cs-conversation, .cs-conversation > *, .cs-conversation > * > * {
    background-color: var(--f2m-blue)!important;
    color: var(--f2m-white)!important;
}
.cs-conversation.cs-selected, .cs-conversation.cs-selected > *, .cs-conversation.cs-selected > * > *{
    background-color: var(--f2m-orange)!important;
}
.cs-conversation:hover{
    box-sizing: border-box;
    border-left: 1rem solid var(--f2m-orange)!important;
    padding-left: 0.5rem;
}
.conversationList{
    background-color: var(--f2m-blue)!important;
    color: var(--f2m-white)!important;
    border: none !important;
}
.cs-message-list{
    border: none!important;
}
.cs-message-input{
    border-top: 1px solid var(--f2m-blue)!important;
}
.cs-message-list, .cs-message-input{
    background-color: var(--f2m-orange-light)!important;
}
.main-container{
    background-color: var(--f2m-blue-light)!important;
    border: 1px solid var(--f2m-blue)!important;
}
.cs-search, .cs-search__input{
    background-color: var(--f2m-blue-light)!important;
}
.cs-conversation__unread-dot{
    background: radial-gradient(circle at 5px 5px, var(--f2m-orange), #9d6f00);
}
.message-container{
    min-height: 150px;
    max-height: 100%;
}
.modal-chat > .cs-message-list.message-container{
    height: 150px;
}
.chat-alert{
    position: absolute;
    z-index: 1;
    bottom: 50px;
}