.RenderAutomationSteps {
  display: flex;
}

.RenderAutomationStepsTag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  background-color: #F2AA00;
  color: #FFF;
  font-family: Roboto;
  font-weight: 500;
  cursor: default;
}

.RenderAutomationSteps :first-child {
  border-radius: 20px 0 0 20px;
}

.RenderAutomationSteps :last-child {
  border-radius: 0 20px 20px 0;
}

.RenderAutomationSteps span[data-automation-step="1"] {
  background-color: var(--green);
}

.RenderAutomationSteps span[data-automation-step="0"] {
  background-color: var(--red);
}
